import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';

Vue.use(Vuex)

export default ({
  state: {
    ListRegLog:[],
    listSpecIntr: [],
    listCompanyIntr: [],
    listInterview: [],
    listRequest: [],
    listRequestTitle: [],
    
},
  getters: {
    ListRegLog(state){
      return state.ListRegLog;
    },
    listSpecIntr(state){
      return state.listSpecIntr;
    },
    listCompanyIntr(state){
      return state.listCompanyIntr;
    },
    listInterview(state){
      return state.listInterview;
    },
    listRequest(state){
      return state.listRequest;
    },
    listRequestTitle(state) {
      return state.listRequestTitle;
    }
  },


  mutations: {
    UpdateListRegLog(state, payload) {
      state.ListRegLog = payload;

    },
    UpdateListInfoInterv(state, payload) {
    for (let index = 0; index < payload.spec.length; index++) {
      state.listSpecIntr.push(payload.spec[index].fff);      
    }
    state.listCompanyIntr = payload.project;
    },
    updateInterview(state,payload){
      state.listInterview = payload;
    },

    updateRequest(state, payload){
      state.listRequest = payload;
      for (let index = 0; index < payload.length; index++) {
        state.listRequestTitle.push(payload[index].title);
        
      }
    }
  },


  actions: {
    getListLogRegister({commit}){
      console.log("dd1");
      commit("onLoad", true);
      Axios.post("/api/v1/personal/report/log/reg")
        .then((res) => {
          console.log(res.data);
          commit("onLoad", false);
          commit("UpdateListRegLog", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    setActivateCompany({commit}, payload){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/company/activate", {
        zn: payload
      })
        .then((res) => {
          alert(res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },

    getListInfoCompanySpecAdm({commit}){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/company/infoSpec")
        .then((res) => {
          console.log(res.data.message)
          commit("UpdateListInfoInterv", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },

    setBlockCompany({commit}, payload){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/company/block", {
        zn: payload
      })
        .then((res) => {
          alert(res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getListRequest({commit}){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/company/request")
        .then((res) => {
          console.log(res.data.message.project);
          commit("updateRequest", res.data.message.project)
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },

    getCompanyInfoId({commit}, payload){
      commit("onLoad", true);
      console.log(payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/info/id", {
          zn: payload,
        })
          .then((res) => {
            console.log('dddd');
            console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message[0]);
          })
          .catch(() => {
            console.log("error");
            reject([]);
          });
      });
    },

    getCompanyToSpecAdm({commit}, payload){
      commit("onLoad", true);
      console.log(payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/info/spec/id", {
          zn: payload,
        })
          .then((res) => {
            console.log('dddd');
            console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message[0]);
          })
          .catch(() => {
            console.log("error");
            reject([]);
          });
      });
    },

    saveInterviewAdm({commit}, payload){
      commit("onLoad", true);
      console.log(payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/admin/interview/set", {
          zn: payload,
        })
          .then((res) => {
            console.log('dddd');
            console.log(res.data);
            commit("updateInterview", res.data.message);
            resolve(res.data.message[0]);
          })
          .catch(() => {
            console.log("error");
            reject([]);
          });
      });
    },
    getListInterviewCompany({commit}){
      commit("onLoad", true);
      Axios.post("/api/v1/interview/spec")
        .then((res) => {
          console.log(res.data.message);
          commit("updateInterview", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },

    goodModerCompanyID({commit}, payload) {
      commit("onLoad", true);
      console.log(payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/good/id", {
          zn: payload,
        })
          .then((res) => {
            console.log('dddd');
            console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message);
          })
          .catch(() => {
            console.log("error");
            reject([]);
          });
      });
    },
    badModerCompanyID({commit}, payload) {
      commit("onLoad", true);
      console.log(payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/bad/id", {
          zn: payload,
        })
          .then((res) => {
            console.log('dddd');
            console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message);
          })
          .catch(() => {
            console.log("error");
            reject([]);
          });
      });
    },
  },
})