<template>
<v-container>
    <v-row>
        <v-col class="mb-4">
            <h1 class="display-2 font-weight-bold mb-3">Отлики и активности по запросам </h1>
        </v-col>

    </v-row>
    <v-row>
        <v-col cols='12' md="12">
            <v-card>
                <v-card-title>
                    <v-text-field v-model="search1" append-icon="mdi-magnify" label="Поиск" single-line hide-details></v-text-field>
                </v-card-title>
                <v-data-table :search="search1" :headers="headers" :items="dessert" :items-per-page="5" class="elevation-1" style="width: 100%">
                    <template v-slot:item.activ="{ item }">

                        <v-select :items="['Закрыт', 'Активен']" v-model="item.actTxt" @change="onOffRequst(item.id)" label="Статус"></v-select>

                        <v-btn elevation="2" small target="_blank" style="margin-bottom: 5px;" title="Посмотреть">
                            История
                        </v-btn>

                    </template>
                    <template v-slot:item.title="{ item }">
                        {{ item.title.substr(0, 200) + ' ...' }}
                    </template>
                    <template v-slot:item.link="{ item }">
                        <v-btn elevation="2" small target="_blank" :href="'https://t.me/itrequests/'+item.id_mess" style="margin-bottom: 5px;" title="Посмотреть">
                            Перейти
                        </v-btn>

                    </template>
                </v-data-table>
            </v-card>
        </v-col>

    </v-row>

</v-container>
</template>

<script>
export default {
    data: () => ({
        e1: 'Активен',
        search1:'',
        headers: [{
                text: 'id',
                value: 'id_bx',
            },
            {
                text: 'Менеджер',
                value: 'fio'
            },
            {
                text: 'Инфо о проекте',
                value: 'title'
            },
            {
                text: 'Локация',
                value: 'Location'
            },
            {
                text: 'Роль',
                value: 'role'
            },
            {
                text: 'Уровень',
                value: 'level'
            },
            {
                text: 'Рейт',
                value: 'rate'
            },
            {
                text: 'ссылка',
                value: 'link'
            },
            {
                text: 'Дата создания',
                value: 'dateCreate'
            },
            {
                text: 'Фирма',
                value: 'firms'
            },
            {
                text: 'Статус',
                value: 'activ'
            },
        ],
        //SELECT `id`, `id_user`, `id_bx`, `title`, `discription`, `agent`, `Location`, `level`, `skill`, `lang`, `role`, `experience`, `dateCreate`, `firms`, `tg_enable`, `date_end`, `activ` FROM `request` WHERE 1
    }),
    name: "answerRequest",
    mounted() {
        this.$store.dispatch("getRequestListActive");
    },
    methods: {
        onOffRequst(t) {
            this.$store.dispatch("setRequestListActive", t);
        }
    },
    computed: {
        dessert() {
            return this.$store.getters.ListRequest;
        },
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped></style>
