<template>
  <v-app v-if="isAuthUser && $route.path!='/spec'">
  <v-navigation-drawer
          v-model="drawer"
          :clipped="$vuetify.breakpoint.lgAndUp"
          :disable-resize-watcher = "true"
          temporary
          fixed
      bottom
        
          left>
          <v-divider></v-divider>
          <!-- <p>ssss<br />ssss</p> -->
<v-divider></v-divider>
          <v-list dense nav>
            
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :to="item.to"
              :disabled = "item.dis"
            >
              <!-- <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon> -->

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item
              
              key="проекты"
              link
              target="blank"
              href="https://t.me/itrequests"
            > -->
              <!-- <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon> -->

              <!-- <v-list-item-content>
                <v-list-item-title>Проекты</v-list-item-title>
              </v-list-item-content> 
            </v-list-item>-->
          </v-list>
        </v-navigation-drawer>
    <v-app-bar app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" :disable-resize-watcher = "true"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-btn href="/" text>
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="./assets/brainics_logo_white.png"
            transition="scale-transition"
            width="140"
          />
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      Пожелания по работе платформы пишите в телеграм аккаут <a style="margin-left: 10px" href="https://t.me/bhire_support"> @bhire_support</a> 
      <v-spacer></v-spacer>
      <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="items_list.length"
        :value="items_list.length"
        color="red"
        overlap
      >
      
      <v-btn 
        text 
        class = "btn_notify" 
        style="min-width: 30px;"
        v-bind="attrs"
        v-on="on"
        >
        <v-icon>mdi-bell-ring</v-icon>
      </v-btn>
      </v-badge>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items_list"
          :key="index"
        >
          <v-list-item-title>Актуализируйте информацию по специалисту {{ item.FIO }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
      <v-btn @click="onExit" text>
        <span class="mr-2">Выход</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      
    </v-app-bar>

    <v-row style="margin-top: 52px">

      <v-col cols="12">
        <v-main>
          <router-view />
        </v-main>
      </v-col>
    </v-row>
  </v-app>
  <v-app v-else>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    right: null,
    drawer: false,
    messages: 1,
    //hosts: "./assets/logo-four.png"
    hosts: "./assets/brainics_logo_white.png"
  }),
  computed: {
    isAuthUser() {
      return this.$store.getters.getAuthClient;
    },
    items() {
      return this.$store.getters.getListMenu;
    },
    items_list() {
      return this.$store.getters.getNotification
    },
  },
  components: {},
  methods: {
    onExit() {
      this.$store.dispatch("getExitClient");
      if(this.isAuthUser == false) {
         this.$router.push('/')
      }
    },
  },
  mounted(){
    this.$store.dispatch("getNotificationUser");
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.btn_notify {
  width: 30px; 
  height: 30px;
   
  min-height: 30px;
}
</style>
