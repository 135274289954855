<template>
  <div style="margin-right: 10px">
    <v-dialog v-model="dialogResume" width="1200px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <resume-specialist :dataSpecialist="dataSpecialist"></resume-specialist>
         
              
              
                <v-btn color="#D9D9D9" style="margin: 5px;" @click="onReadSpec(dataSpecialist.id)" title="редактировать специалиста" >
                  Изменить
                </v-btn>
                <v-btn color="#D9D9D9"  style="margin: 5px;" @click="OpenDialog(dataSpecialist.act)" title="сменить статус" >
                  Доступность
                </v-btn>
                <v-btn color="#D9D9D9" style="margin: 5px;" @click="exportPdf(dataSpecialist.id, 0)" title="Удалить специалиста" >
                  Сохранить в PDF
                </v-btn>
                <v-btn color="#D9D9D9" style="margin: 5px;" @click="exportWord(dataSpecialist.id, 0)" title="Удалить специалиста" >
                  Сохранить в DOC
                </v-btn>
                <v-btn color="#D9D9D9" style="margin: 5px;" @click="exportPdf(dataSpecialist.id, 1)" title="Удалить специалиста" >
                  Сохранить в PDF с логотипом
                </v-btn>
                <v-btn color="#D9D9D9" style="margin: 5px;" @click="exportWord(dataSpecialist.id, 1)" title="Удалить специалиста" >
                  Сохранить в DOC с логотипом
                </v-btn>
                <v-btn color="#D9D9D9" style="margin: 5px;" @click="OpenDialogResume(dataSpecialist.id)" title="посмотреть СМ специалиста" >
                  Посмотреть
                </v-btn>
                <v-btn color="#D9D9D9" style="margin: 5px;" @click="deleteDialog(dataSpecialist.id)" title="Удалить специалиста" 
                  disabled>
                  Удалить
                </v-btn>
              
            
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="#D9D9D9" @click="dialogResume = false"> Закрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" width="500px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="12" md="12">
              <h2>Редактирование</h2>
            </v-col>
            <v-col cols="12">
              <p>Для актуализации специалиста, дата когда освободиться специалист должна быть не познее недельной
                давности.</p>
            </v-col>
            <v-col cols="12" md="12">
              <v-date-picker v-model="date" no-title scrollable @click:date="SaveStatus(date)">

              </v-date-picker>
            </v-col>
          </v-row>
          <v-row>

          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <!-- <v-card-actions>
          <v-btn color="primary" text @click="onClose"> Отменить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="SaveStatus"> Сохранить </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-row align="center" justify="space-around" style="margin-bottom: 10px">
      <!-- <v-btn depressed> СКАЧАТЬ ТАБЛИЦУ CV </v-btn> -->
      <!-- <v-btn depressed @click="onNewData"> ДОБАВИТЬ СПЕЦИАЛИСТА + </v-btn> -->
      <v-btn color="#FFE816" depressed to="/person"> ДОБАВИТЬ СПЕЦИАЛИСТА + </v-btn>
    </v-row>
    <v-card>
      <v-container fluid>
        <v-row align="center">
          <v-col cols="3">
            <v-autocomplete v-model="find.country" :items="listLocation" outlined dense chips small-chips label="Локация"
              multiple @change="onFind"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete v-model="find.location" :items="listLocation" outlined dense chips small-chips
              label="Гражданство" multiple @change="onFind"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete v-model="find.lang" :items="langLevel" outlined dense chips small-chips label="Английский"
              multiple @change="onFind"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete v-model="find.level" :items="listLevel" outlined dense chips small-chips label="Уровень"
              multiple @change="onFind"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete v-model="find.role" :items="categories" outlined dense chips small-chips label="Роль" multiple
              @change="onFind"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="find.rate" label="Ставка" outlined dense chips @change="onFind"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-autocomplete v-model="find.skills" :items="listSkills" outlined dense chips small-chips label="Навыки"
              multiple @change="onFind"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete v-model="find.pub" :items="listPub" outlined dense chips small-chips label="Статус CV"
              multiple @change="onFind"></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div style="margin-top: 10px" ref="word123">
      <element-loading :active="onLoadData" spinner="spinner" color="#FF6700" />
      <v-card>
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="desserts" :items-per-page="15" :search="search" class="elevation-1">
          <template v-slot:item.act="{ item }">
            <v-btn
            elevation="2"
            small
            @click="OpenDialogResume(item.id)"
            target="_blank"
            style="margin-bottom: 5px;"
            title="редактировать"
            color="#D9D9D9"
          >
          Посмотреть          
          </v-btn>
            
          </template>
        </v-data-table>
      </v-card>
    </div>
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
      :paginate-elements-by-height="1400" filename="CV.pdf" :pdf-quality="2" :manual-pagination="true" pdf-format="a4"
      pdf-orientation="portrait" pdf-content-width="700px" @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)" ref="html2Pdf">
      <v-content slot="pdf-content" style="width: 700px; margin: 0px;">
        <section style="margin-left: 75px" v-if="Object.keys(dataSpecialist).length !== 0">
          <img v-if="dataSpecialist.visimg == 1" :src="dataSpecialist.logobase" alt="Картинка" width=100>
          <h2 style="text-align:center; margin-bottom: 50px;">id: {{ dataSpecialist.id }} </h2>

          <p style="margin-top: 5px;"><b>Локация: </b> {{ dataSpecialist.Country }}</p>
          <p style="margin-top: 5px;"><b>Роль: </b> {{ dataSpecialist.Category }}</p>
          <p style="margin-top: 5px;"><b>Уровень английского: </b> {{ dataSpecialist.English }}</p>
          <p style="margin-top: 5px;"><b>Уровень: </b> {{ dataSpecialist.Level }}</p>
          <p style="margin-top: 5px;"><b>Стоимость часа(руб.): </b> {{ dataSpecialist.zakyp_rub }}</p>
          <p style="margin-top: 5px;"><b>Часовой пояс: </b> {{ dataSpecialist.Timezone }}</p>
          <p style="margin-top: 5px;"><b>Комерческий опыт: </b> {{ dataSpecialist.Years_of_Experience }}</p>
          <p style="margin-top: 5px;"><b>О специалисте: </b> {{ dataSpecialist.about }}</p>
          <h2 style="text-align:center; margin-top: 30px; margin-bottom: 30px;">Навыки</h2>


          <div style="display: block; justify-content: flex-start;" v-if="dataSpecialist.levelSkill.length != 0">
          <div style="height: 30px; display: inline-block; background-color: #e0e0e0; border-radius: 15px; padding: 5px; margin: 5px;" v-for="(n, i) in dataSpecialist.levelSkill" :key="i">
            {{ n.name }} 
          </div>
          </div>
          
          
          <p style="margin-top: 5px;" v-if="dataSpecialist.dopskill != ''"> {{ dataSpecialist.dopskill }}</p>
          <!-- :style="i%2==0?'background-color:#ddd':''" -->
          
        </section>
        <section style="margin-left: 75px" v-if="Object.keys(dataSpecialist).length !== 0">
          <h2 style="margin-top: 5px; text-align:center; margin-top: 30px; margin-bottom: 30px;">Образование</h2>
          <table style="width:100%">
            <tr>
              <th style="text-align: left;">Уровень образования</th>
              <th style="text-align: left;">Название учебного заведения</th>
              <th style="text-align: left;">Срок обучения</th>
            </tr>
            <tr v-for="(n, i) in dataSpecialist.educInfo" :key="i">
              <td>{{ n.type }}</td>
              <td>{{ n.name }}</td>
              <td>{{ n.dts }}</td>
            </tr>
          </table>
        </section>
        <section style="margin-left: 75px;" v-if="Object.keys(dataSpecialist).length !== 0">
          <h2 style="margin-top: 5px; text-align:center; margin-top: 30px; margin-bottom: 30px;"> Опыт в комерческой
            разработке</h2>
          <section :class="i==0?'pdf-item_in':'pdf-item'" v-for="(n, i) in dataSpecialist.ind" :key="i">
            <p style="margin-top: 5px;"><b>Проект: </b>{{ n.name }}</p>
            <p style="margin-top: 5px;"><b>Индустрия: </b>{{ n.ind }}</p>
            <p style="margin-top: 5px;"><b>Срок исполнения:</b> {{ n.date_start }} - {{ n.date_ent }}</p>
            <p style="margin-top: 5px;"><b>Роль в проекте: </b> {{ n.role }}</p>
            <p style="margin-top: 5px;"><b>Навыки: </b>{{ n.skill.toString() }}</p>
            <p style="margin-top: 5px;"><b>Описание проекта: </b>{{ n.description }}</p><br />
            
          </section>
          
          
        </section>
        

      </v-content>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import ResumeSpecialist from "@/components/admin/dialog/Resume.vue"
export default {
  data: () => ({
    ааа: "",
    word: '',
    dialog: false,
    dialogResume: false,
    exp: false,
    edu: false,
    search: "",
    files: "",
    search_exp: "",
    uploadFoto: false,
    date: "",
    dataSpecialist: [],
    statusPers: {
      id: 0,
      dt: "",
    },

    project: {
      dts: "",
      dte: "",
      role: "",
      ind: "",
      descr: "",
      kol: "",
      skill: [],
      poln: "",
      read: -1,
      firm: '',
    },
    education: {
      title: "",
      dtn: "",
      dte: "",
      level: "",
      spec: "",
      read: -1,
    },
    data: {
      id: 0,
      name: "",
      abbr: "",
      nameRus: "",
    },
    spec: {
      id: 0,
      foto: "",
      about: "",
      fio: "",
      cat: "",
      role: "",
      level: "",
      grag: "",
      loc: "",
      zone: "",
      firm: "",
      exp: "",
      rater: "",
      lang: [
        {
          name: "Английский",
          level: "a1 - beginner",
        },
      ],
      educ: [],
      skills: [],
      exp_proj: [],
    },
    tab: null,
    items2: ["Интро о специалисте, описание софт скиллов", "Навыки", "Опыт", "Образование"],
    find: {
      country: [],
      location: [],
      lang: [],
      level: [],
      role: [],
      rate: [],
      skills: [],
      pub: [],
    },
    items: ["foo", "bar", "fizz", "buzz"],
    values: [],
    value: null,

    headers_exp: [
      { text: "Роль", value: "role" },
      { text: "Начало", value: "dts" },
      { text: "Конец", value: "dts" },
      { text: "Индустрия", value: "ind" },
      { text: "кол-во чел", value: "kol" },
    ],
    headers_edгс: [
      {
        text: "Название",
        value: "title",
      },
      { text: "Дата начала", value: "dtn" },
      { text: "Дата окончания", value: "dte" },
      { text: "уровень", value: "level" },
      { text: "специальность", value: "spec" },
    ],
    desserts_exp: [],
    menu: false,
    menu2: false,
    menu_edu_dtn: false,
    menu_edu_dte: false,
    nameRules: [
      (v) => !!v || "Название не может быть пустым",
      (v) => (v && v.length >= 1) || "Name must be less than 10 characters",
    ],
    state: {
      name: 'San Luis Potosi',
      map: 'data:image/png;base64',
      municipalities: [
        { name: 'San Luis Potosi', population: 824000 },
        { name: 'Rio Verde', population: 160000 },
        { name: 'Cd Valles', population: 176000 },
        { name: 'Matehuala', population: 82726 }
      ],
      tourist_attractions: [
        'Tamtoc', 'Sótano de las Golondrinas', 'Cascada de Tamul'
      ]
    }
  }),
  components: {
    ResumeSpecialist, VueHtml2pdf
  },
  computed: {
    headers() {
      return this.$store.getters.getListheader;
    },
    listLocation() {
      return this.$store.getters.getListLocation;
    },
    listLang() {
      return this.$store.getters.getListLang;
    },
    listLevel() {
      return this.$store.getters.getListLevel;
    },
    listRole() {
      return this.$store.getters.getListRole;
    },
    listRate() {
      return this.$store.getters.getListRate;
    },
    listPub() {
      return this.$store.getters.getListPub;
    },
    listSkills() {
      return this.$store.getters.getListSkills;
    },
    dataCVPerson() {
      return this.$store.getters.getPersonCV;
    },
    desserts() {
      return this.$store.getters.getListPersonSpec;
    },
    expList() {
      return this.$store.getters.getListExp;
    },
    country() {
      return this.$store.getters.getListLocation;
    },
    langLevel() {
      return this.$store.getters.getListLangLevel;
    },
    langName() {
      return this.$store.getters.getListLang;
    },
    levelSpec() {
      return this.$store.getters.getListLevel;
    },
    roleSpec() {
      return this.$store.getters.getListRole;
    },
    timeZone() {
      return this.$store.getters.getTimeZone;
    },
    skills() {
      return this.$store.getters.getListSkills;
    },
    categories() {
      return this.$store.getters.getListCategories;
    },
    url() {
      return this.$store.getters.getBaseUrl;
    },
    company() {
      return this.$store.getters.getListCompany;
    },
    industries() {
      return this.$store.getters.getListIndustries;
    },
    levelEduc() {
      return this.$store.getters.getListLevelEducation;
    },
    educationList() {
      return this.$store.getters.getListEducation;
    },
    load() {
      return this.$store.getters.getOnLoad;
    },
    CountryToTimeZone() {
      return this.$store.getters.getOnListContryTimeZone;
    },
    onLoadData() {
      return this.$store.getters.getOnLoad;
    },
    statusUser() {
      return this.$store.getters.getStatusUser;
    }
  },
  name: "cabinet",
  mounted() {
    this.$store.dispatch("getListDataFirm").then((res) => {

      this.ааа = res[0].bank;
      console.log("bank");
      console.log(this.aaa);
    });
    this.$store.dispatch("getListPersonSpecAction");
    this.$store.dispatch("getLocationList");
    this.$store.dispatch("getSkillsList");
    this.$store.dispatch("getLangLevelList");
    this.$store.dispatch("getLangList");
    this.$store.dispatch("getLevelList");
    this.$store.dispatch("getRoleList");
    this.$store.dispatch("getTimeZone");
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getCompanyList");
    this.$store.dispatch("getIndustriesList");
    this.$store.dispatch("getLevelEducationList");
    this.$store.dispatch("getEducationList");
    this.$store.dispatch("getCountryToTimeZone");
  },
  methods: {
    OpenDialogResume(t) {
      this.$store.dispatch("getSpecialistInfoId", t).then(res => {
        console.log('dddd');
        console.log(res);
        this.dataSpecialist = res;
      })
      this.dialogResume = true;
    },
    exportWord(t, g) {
      this.$store.dispatch("getSpecialistInfoId", t).then(res => {
        console.log(res);
        this.dataSpecialist = res;
        let word = `<html xmlns:o='urn:schemas-microsoft-com:office:office xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
        <head>
          <meta charset='utf-8'>
          <title>Export HTML to Word Document with JavaScript</title>
          </head>
          <body>`
        if (g == 1) {
          word = word + `<img src="` + res.logobase + `" width=100 height=100>`
        }
        word = word + `<p><h2 style="text-align:center; margin-bottom: 50px;">id: ` + res.id + ` | ` + res.FIO + `</h2></p> 
            <p style="margin-top: 5px;"><b>Локация:</b> `+ res.Country + `</p> 
            <p style="margin-top: 5px;"><b>Роль:</b> `+ res.Category + `</p> 
            <p style="margin-top: 5px;"><b>Уровень английского:</b> `+ res.English + `</p> 
            <p style="margin-top: 5px;"><b>Уровень:</b> `+ res.Level + `</p> 
            <p style="margin-top: 5px;"><b>Стоимость часа(руб.):</b> `+ res.zakyp_rub + `</p> 
            <p style="margin-top: 5px;"><b>Часовой пояс:</b> `+ res.Timezone + `</p> 
            <p style="margin-top: 5px;"><b>Комерческий опыт:</b> `+ res.Years_of_Experience + `</p> 
            <p style="margin-top: 5px;"><b>О специалисте:</b> `+ res.about + `</p> 
            <h2  style="text-align:center; margin-top: 30px; margin-bottom: 30px;">Навыки</h2> 
            <table style="width:100%"><tr><th style="text-align: left;">Навык</th><th style="text-align: left;">Уровень владения</th><th style="text-align: left;">Срок</th></tr>`
        for (let index = 0; index < res.levelSkill.length; index++) {
          word = word + `<tr><td>` + res.levelSkill[index].name + `</td> <td>` + res.levelSkill[index].type + `</td> <td>` + res.levelSkill[index].time + `</td></tr>`;
        }
        word = word + `</table><h2 style="margin-top: 5px; text-align:center; margin-top: 30px; margin-bottom: 30px;">Опыт в комерческой разработке</h2>`;
        for (let index = 0; index < res.ind.length; index++) {
          word = word + `<p style="margin-top: 5px;"><b>Проект:</b> ` + res.ind[index].name + `</p>
            <p style="margin-top: 5px;"><b>Индустрия:</b> `+ res.ind[index].ind + `</p>
            <p style="margin-top: 5px;"><b>Срок исполнения:</b> `+ res.ind[index].date_start + `-` + res.ind[index].date_ent + `</p>
            <p style="margin-top: 5px;"><b>Роль в проекте:</b> `+ res.ind[index].role + `</p>
            <p style="margin-top: 5px;"><b>Навыки:</b> `+ res.ind[index].skill + `</p>
            <p style="margin-top: 5px;"><b>Описание проекта:</b> `+ res.ind[index].description + `</p><br /><br />
            `;

        }
        word = word + `<h2 style="margin-top: 5px; text-align:center; margin-top: 30px; margin-bottom: 30px;">Образование</h2><table style="width:100%"><tr><th style="text-align: left;">Уровень образования</th><th style="text-align: left;">Название учебного заведения</th><th style="text-align: left;">Срок обучения</th></tr>`
        for (let index = 0; index < res.educInfo.length; index++) {
          word = word + `<tr><td>` + res.educInfo[index].type + `</td> <td>` + res.educInfo[index].name + `</td> <td>` + res.educInfo[index].dts + `</td></tr>`;

        }
        word = word + `</table></body></html>`;

        let source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(word);
        let fileDownload = document.createElement("a");
        document.body.appendChild(fileDownload);
        fileDownload.href = source;
        fileDownload.download = 'document.doc';
        fileDownload.click();
        document.body.removeChild(fileDownload);
      })
    },
    exportPdf(t, g) {
      console.log(t);
      this.$store.dispatch("getSpecialistInfoId", t).then(res => {
        console.log(res);

        this.dataSpecialist = res;
        this.dataSpecialist.visimg = g;
        console.log(this.dataSpecialist);

        this.$refs.html2Pdf.generatePdf()
      })
      this.$refs.html2Pdf.set({
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      });

    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      }).save()
    },

    getCVPerson(e) {
      console.log(e)
      //this.$store.dispatch("getPersonCV", e.id);
      this.spec.id = e.id;
      this.spec.grag = e.nameCountryRus;
      this.spec.fio = e.FIO;
      this.spec.level = e.nameLevel;
      this.spec.rater = e.Rate_RUB;
      this.spec.rolecat = e.nameCategories;
      this.spec.firm = e.nameFirm;
      this.spec.exp = e.Years_of_Experience;
      this.spec.cat = e.nameCategories;
      this.spec.zone = e.nameTimeZone;
      this.spec.about = e.about;
      this.spec.educ = e.edu;
      this.spec.exp_proj = e.exp;
      this.spec.skills = e.skills;
      this.spec.about = e.about;
      this.dialog = true;
    },
    deleteDialog(e) {
      if (confirm("Вы уверены что хотите удалить данного специалиста?")) {
        this.$store.dispatch("onDeleteUser", e);
        this.$store.dispatch("getListPersonSpecAction");
      }

    },
    onReadSpec(t) {

      //this.$router.push('/person');
      this.$store.dispatch("getSpecInfoId", t).then((res) => {
        console.log("sdfsdf");
        if (res) {
          this.$router.push('/person');
        } else {
          alert('ой что то пошло не так! По пробуйте чуть позже!')
        }
      })

    },
    onFind() {
      this.$store.dispatch("onSeachSpecial", this.find);
    },
    onClose() {
      this.dialog = false;
    },
    readData(e) {
      this.$store.dispatch("getPersonCV", e.id).then(() => {
        this.spec.id = e.id;
        this.spec.grag = this.dataCVPerson.nameCountry;
        this.spec.fio = this.dataCVPerson.FIO;
        this.spec.level = this.dataCVPerson.nameLevel;
        this.spec.rater = this.dataCVPerson.Rate_RUB;
        this.spec.cat = this.dataCVPerson.nameRole;
        this.spec.firm = this.dataCVPerson.nameFirm;
        this.spec.exp = this.dataCVPerson.Years_of_Experience;
        this.spec.role = this.dataCVPerson.nameCategories;
        this.spec.zone = this.dataCVPerson.nameTimeZone;
        this.spec.about = this.dataCVPerson.about;
        this.spec.educ = this.dataCVPerson.edu;
        this.spec.exp_proj = this.dataCVPerson.exp;
        this.spec.skills = this.dataCVPerson.skills;
        this.spec.lang = this.dataCVPerson.lang;
        this.dialog = true;
      });
    },
    readEducation(e, r) {
      console.log(r);
      this.education.title = e.title;
      this.education.level = e.level;
      this.education.spec = e.spec;
      this.education.dtn = e.dtn;
      this.education.dte = e.dte;
      this.education.read = r.index;
    },
    readDataProj(e, r) {
      this.project.title = e.title;
      this.project.dts = e.dts;
      this.project.dte = e.dte;
      this.project.role = e.role;
      this.project.ind = e.ind;
      this.project.descr = e.descr;
      this.project.kol = e.kol;
      this.project.skill = e.skill;
      this.project.poln = e.poln;
      this.project.read = r.index;
    },
    DelDataEdu() {
      let n = confirm("Вы уверены что хотите удалить?");
      if (n == true) {
        this.spec.educ.splice(this.education.read, 1);
        this.education.title = "";
        this.education.dtn = "";
        this.education.dte = "";
        this.education.level = "";
        this.education.spec = "";
        this.education.read = -1;
      }
    },
    delDataExp() {
      let n = confirm("Вы уверены что хотите удалить?");
      if (n == true) {
        this.spec.exp_proj.splice(this.education.read, 1);
        this.project.title = "";
        this.project.dts = "";
        this.project.dte = "";
        this.project.role = "";
        this.project.ind = "";
        this.project.descr = "";
        this.project.kol = "";
        this.project.skill = [];
        this.project.poln = "";
        this.project.read = -1;
      }
    },
    onNewData() {
      this.spec.id = 0;
      this.spec.grag = "";
      this.spec.fio = "";
      this.spec.level = "";
      this.spec.rater = '';
      this.spec.cat = '';
      this.spec.firm = '';
      this.spec.exp = '';
      this.spec.role = '';
      this.spec.zone = '';
      this.spec.about = '';
      this.spec.educ = [];
      this.spec.exp_proj = [];
      this.spec.skills = [];
      this.spec.lang = [
        {
          name: "Английский",
          level: "a1 - beginner",
        },
      ];
      this.dialog = true;
    },
    saveData() {
      this.spec.foto = this.url;
      this.$store.dispatch("saveSpec", this.spec);
      this.dialog = false;
    },
    saveDataExp() {
      this.$store.dispatch("AddListExp", this.project);
      if (this.project.read == -1) {
        this.spec.exp_proj.push(JSON.parse(JSON.stringify(this.project)));
      } else {
        this.spec.exp_proj[this.project.read].title = this.project.title;
        this.spec.exp_proj[this.project.read].dts = this.project.dts;
        this.spec.exp_proj[this.project.read].dte = this.project.dte;
        this.spec.exp_proj[this.project.read].role = this.project.role;
        this.spec.exp_proj[this.project.read].ind = this.project.ind;
        this.spec.exp_proj[this.project.read].descr = this.project.descr;
        this.spec.exp_proj[this.project.read].kol = this.project.kol;
        this.spec.exp_proj[this.project.read].skill = this.project.skill;
        this.spec.exp_proj[this.project.read].poln = this.project.poln;
      }
      this.project.title = "";
      this.project.dts = "";
      this.project.dte = "";
      this.project.role = "";
      this.project.ind = "";
      this.project.descr = "";
      this.project.kol = "";
      this.project.skill = [];
      this.project.poln = "";
      this.project.read = -1;
      this.exp = false;
    },
    setFoto() {
      if (this.files != null) {
        this.uploadFoto = true;
        this.$store.dispatch("setFotoSpec", this.files);
      } else {
        this.uploadFoto = false;
      }
    },
    savedateE(t) {
      this.statusPers.dt = t;
      this.menu_edu_dte = false;
    },
    OpenDialog(t) {
      this.statusPers.id = t;
      this.dialog = true;
    },
    SaveStatus(t) {
      this.dialog = false;
      this.statusPers.dt = t;
      console.log(t);
      this.$store.dispatch("saveStatus", this.statusPers).then(res => {
        if (res) {

          this.statusPers.id = 0;
          this.statusPers.dt = "";
        } else {
          alert("Возникли проблемы сохранения статуса попробуйте позже!")
        }
      });

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>
.infoPerson {
  text-align: left;
}

.btn_read {
  width: 121px;
}

pre {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
  overflow-y: hidden;
  line-height: 20px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.vue-html2pdf {
  .layout-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: -100vw;
    top: 0;
    z-index: -9999;
    background: rgba(95, 95, 95, 0.8);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;

    &.show-layout {
      left: 0vw;
      z-index: 9999;
    }

    &.unset-all {
      all: unset;
      width: auto;
      height: auto;
    }
  }

  .pdf-preview {
    position: fixed;
    width: 75%;
    min-width: 600px;
    height: 80vh;
    top: 100px;
    z-index: 9999999;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    box-shadow: 0px 0px 10px #00000048;

    button {
      position: absolute;
      top: -20px;
      left: -15px;
      width: 35px;
      height: 35px;
      background: #555;
      border: 0;
      box-shadow: 0px 0px 10px #00000048;
      border-radius: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      cursor: pointer;
    }

    iframe {
      border: 0;
    }
  }

  .transition-anim-enter-active,
  .transition-anim-leave-active {
    transition: opacity 0.3s ease-in;
  }

  .transition-anim-enter,
  .transition-anim-leave-to {
    opacity: 0;
  } 

  .pdfLine {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  
}
.pdf-item {
    page-break-inside:auto; 
    page-break-inside:always;
    margin-top: 75px;
  }
  .pdf-item_in {
    page-break-inside:auto; 
    page-break-after:always;
  }
  .pdf-item_in>p {
    page-break-inside:always; 
    page-break-after:avoid;
  }
</style>
