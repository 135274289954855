<template>
  <v-container
  fluid:true
  >
  <v-btn
            elevation="2"
            small
            @click="generateReport()"
            target="_blank"
            style="margin-bottom: 5px;"
            title="сохранить в pdf"
          >
           сохранить в pdf
          </v-btn>

        <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
      :paginate-elements-by-height="1400" filename="CV.pdf" :pdf-quality="2" :manual-pagination="true" pdf-format="a4"
      pdf-orientation="portrait" pdf-content-width="1000px" @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)" ref="html2Pdf">
      <v-content slot="pdf-content" style="width: 700px">
        <div style="margin-left: 75px" v-if="Object.keys(dataSpecialist).length !== 0">
          <h2 style="text-align:center; margin-bottom: 50px;">id: {{ dataSpecialist.id }} | {{ dataSpecialist.FIO }} </h2>

          <p style="margin-top: 5px;"><b>Локация: </b> {{ dataSpecialist.Country }}</p>
          <p style="margin-top: 5px;"><b>Роль: </b> {{ dataSpecialist.Category }}</p>
          <p style="margin-top: 5px;"><b>Уровень английского: </b> {{ dataSpecialist.English }}</p>
          <p style="margin-top: 5px;"><b>Уровень: </b> {{ dataSpecialist.Level }}</p>
          <p style="margin-top: 5px;"><b>Стоимость часа(руб.): </b> {{ dataSpecialist.zakyp_rub }}</p>
          <p style="margin-top: 5px;"><b>Часовой пояс: </b> {{ dataSpecialist.Timezone }}</p>
          <p style="margin-top: 5px;"><b>Комерческий опыт: </b> {{ dataSpecialist.Years_of_Experience }}</p>
          <p style="margin-top: 5px;"><b>О специалисте: </b> {{ dataSpecialist.about }}</p>
          <h2 style="text-align:center; margin-top: 30px; margin-bottom: 30px;">Навыки</h2>

          <p style="margin-top: 5px;"> {{ dataSpecialist.Main_skills.toString() }}</p>

          <table v-if="dataSpecialist.levelSkill.length!=0 " style="width:100%">
            <tr>
              <th style="text-align: left;">Навык</th>
              <th style="text-align: left;">Уровень владения</th>
              <th style="text-align: left;">Срок</th>
            </tr>
            <tr v-for="(n, i) in dataSpecialist.levelSkill" :key="i">
              <td>{{ n.name }}</td>
              <td>{{ n.type }}</td>
              <td>{{ n.time }}</td>
            </tr>
          </table>


          <h2 style="margin-top: 5px; text-align:center; margin-top: 30px; margin-bottom: 30px;"> Опыт в комерческой
            разработке</h2>
          <div v-for="(n, i) in dataSpecialist.ind" :key="i">
            <p style="margin-top: 5px;"><b>Проект: </b>{{ n.name }}</p>
            <p style="margin-top: 5px;"><b>Индустрия: </b>{{ n.ind }}</p>
            <p style="margin-top: 5px;"><b>Срок исполнения:</b> {{ n.date_start }} - {{ n.date_ent }}</p>
            <p style="margin-top: 5px;"><b>Роль в проекте: </b> {{ n.role }}</p>
            <p style="margin-top: 5px;"><b>Навыки: </b>{{ n.skill.toString() }}</p>
            <p style="margin-top: 5px;"><b>Описание проекта: </b>{{ n.description }}</p><br />

          </div>
          <h2 style="margin-top: 5px; text-align:center; margin-top: 30px; margin-bottom: 30px;">Образование</h2>
          <table style="width:100%">
            <tr>
              <th style="text-align: left;">Уровень образования</th>
              <th style="text-align: left;">Название учебного заведения</th>
              <th style="text-align: left;">Срок обучения</th>
            </tr>
            <tr v-for="(n, i) in dataSpecialist.educInfo" :key="i">
              <td>{{ n.type }}</td>
              <td>{{ n.name }}</td>
              <td>{{ n.dts }}</td>
            </tr>
          </table>

        </div>


      </v-content>
    </vue-html2pdf>
    </v-container>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  data: () => ({
    dataSpecialist: [],
  }),
  name: "CV",
  methods: {
    
    generateReport () {
      this.$refs.html2Pdf.set({
          pagebreak: {avoid: 'p'}
      });
            this.$refs.html2Pdf.generatePdf()
        },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(10)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        }
  },
  components: {
        VueHtml2pdf
    },
  mounted(){  
    let t = this.$route.params.id;
    console.log(this.$route.params.id);
    this.$store.dispatch("getSpecialistInfoId", t).then(res => {
        console.log(res);

        this.dataSpecialist = res;
        console.log(this.dataSpecialist);

        this.$refs.html2Pdf.generatePdf()
      })
  },
  computed:{
    desserts() {
      return this.$store.getters.InfoSpec;
    },
    spec() {
      return this.$store.getters.getSpecialId;
    }, 
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
pre {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  width: 100%;
	padding: 0;
	margin: 0;
	overflow: auto;
	overflow-y: hidden;
	line-height: 20px;
  white-space: pre-wrap;      
   white-space: -moz-pre-wrap;  
   white-space: -pre-wrap;      
   white-space: -o-pre-wrap;  
   word-wrap: break-word;
}
.vue-html2pdf {
	.layout-container {
		position: fixed;
		width: 100vw;
		height: 100vh;
		left: -100vw;
		top: 0;
		z-index: -9999;
		background: rgba(95, 95, 95, 0.8);
		display: flex;
		justify-content: center;
		align-items: flex-start;
		overflow: auto;
		&.show-layout {
			left: 0vw;
			z-index: 9999;
		}
		&.unset-all {
			all: unset;
			width: auto;
			height: auto;
		}
	}
	.pdf-preview {
		position: fixed;
		width: 65%;
		min-width: 600px;
		height: 80vh;
		top: 100px;
		z-index: 9999999;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 5px;
		box-shadow: 0px 0px 10px #00000048;
		button {
			position: absolute;
			top: -20px;
			left: -15px;
			width: 35px;
			height: 35px;
			background: #555;
			border: 0;
			box-shadow: 0px 0px 10px #00000048;
			border-radius: 50%;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			cursor: pointer;
		}
		iframe {
			border: 0;
		}
	}
	.transition-anim-enter-active, .transition-anim-leave-active {
		transition: opacity 0.3s ease-in;
	}
	.transition-anim-enter, .transition-anim-leave-to{
		opacity: 0;
	}
}
</style>
